<template>
  <div class="kaocha">
    <van-swipe
      class="my-swipe"
      indicator-color="white"
      :autoplay="false"
      :show-indicators="true"
      :indicator-active-class="'indicator-active'"
      :indicator-inactive-class="'indicator-inactive'"
      @change="handleChange"
    >
      <van-swipe-item class="item_image">
        <div>
          <video
            ref="video2"
            id="video2"
            class="video-js vjs-default-skin"
            controls
            :autoplay="true"
            preload="auto"
            x5-playsinline=""
            webkit-playsinline=""
            playsinline=""
            poster=""
            x-webkit-airplay="allow"
            src="../../assets/image/five/yongcan.mp4"
          ></video>
        </div>
        <div class="item_value" style="white-space: pre-line">
          {{ stringObj[0].value }}
        </div>
        <div class="item_yongcan">
          <img src="../../assets//image/five/yongcan.jpg" alt="" />
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import { getScheduleinfoApi } from "@/api/home.js"
export default {
  data () {
    return {
      stringObj: []
    }
  },
  mounted () {
    this.getList()
    this.initVideos();
  },
  methods: {
    getList () {
      this.leftList = []
      this.rightList = []
      let params = {
        id: 2
      }
      getScheduleinfoApi(params).then(res => {
        this.stringObj = res
      })
    },
    initVideos () {
      // 获取所有视频元素
      const videos = this.$refs;
      // 遍历所有视频元素并设置样式
      Object.keys(videos).forEach((key) => {
        if (key.startsWith('video')) {
          const videoElement = videos[key][0];
          videoElement.style.width = '100%';
          videoElement.style.height = '100%';
          videoElement.style.objectFit = 'cover';
          videoElement.style.position = 'absolute';
          videoElement.style.top = '50%';
          videoElement.style.left = '50%';
          videoElement.style.transform = 'translate(-50%, -50%)';
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.kaocha {
  margin: 0 20px;
  padding: 0 20px;
  overflow: auto;
}

/* 覆盖 Vant 的默认样式 */
::v-deep .van-swipe__indicators {
  bottom: 20px; /* 根据需要调整指示器距离底部的距离 */
}

/* 自定义选中状态的指示器颜色 */
::v-deep .van-swipe__indicator--active {
  background-color: white !important; /* 绿色指示器 */
}

/* 可以添加更多的样式来调整指示器的大小和形状 */
::v-deep .van-swipe__indicator {
  width: 20px; /* 指示器的宽度 */
  height: 3px; /* 指示器的高度 */
  border-radius: 0; /* 去除圆角 */
  background-color: white; /* 非选中状态的颜色 */
  opacity: 0.5; /* 非选中状态的透明度 */
  margin: 0 5px; /* 指示器之间的间距 */
}

.item_image {
  width: 100%;
  height: 1100px;
  video {
    max-width: 100%;
    max-height: 300px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .item_value {
    font-size: 32px;
    margin-top: 400px;
  }
  .item_yongcan {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #414244;
    width: 720px;
    height: 500px;
    margin-left: -3%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>