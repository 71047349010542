<template>
  <div class="home">
    <div class="home_bg"></div>
    <div class="home_main">
      <div
        v-for="(item, index) of list"
        :key="index"
        class="list"
        @click="toggleBackground(item)"
      >
        <div
          class="item"
          :style="{
            'background-image':
              'url(' + (item.isActive ? item.jpg : item.png) + ')',
          }"
        >
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="home_line"></div>
    <keep-alive include="richeng,renyuan">
      <component :is="comName"> </component>
    </keep-alive>
  </div>
</template>
<script>
import richeng from '../components/home/richeng.vue'
import renyuan from '../components/home/renyuan.vue'
import yongcan from '../components/home/yongcan.vue'
import zhusu from '../components/home/zhusu.vue'
import kaocha from '../components/home/kaocha.vue'
import fuwu from '../components/home/fuwu.vue'
import xiangqing from '../components/home/xiangqing.vue'
import ditu from '../components/home/ditu.vue'

export default {
  components: {
    richeng,
    renyuan,
    yongcan,
    zhusu,
    kaocha,
    fuwu, xiangqing, ditu
  }, data () {
    return {
      list: [
        {
          name: '日程', png: require('../assets/tab/richeng.jpg'), val: 'richeng'
          , jpg: require('../assets/tab/richeng2.jpg')
          , isActive: false
        },
        {
          name: '人员', png: require('../assets/tab/renyuan.jpg'), val: 'renyuan',
          jpg: require('../assets/tab/renyuan2.jpg'),
          isActive: false
        },
        {
          name: '用餐', png: require('../assets/tab/yongcan.jpg'), val: 'yongcan'
          , jpg: require('../assets/tab/yongcan2.jpg')
          , isActive: false
        },
        {
          name: '住宿', png: require('../assets/tab/zhusu.jpg'), val: 'zhusu'
          , jpg: require('../assets/tab/zhusu2.jpg')
          , isActive: false
        },
        {
          name: '乡情介绍', png: require('../assets/tab/xiangqing.jpg'), val: 'xiangqing'
          , jpg: require('../assets/tab/xiangqing2.jpg')
          , isActive: false
        },
        {
          name: '考察点', png: require('../assets/tab/kaocha.jpg'), val: 'kaocha'
          , jpg: require('../assets/tab/kaocha2.jpg')
          , isActive: false
        },
        {
          name: '闽中地图', png: require('../assets/tab/ditu.jpg'), val: 'ditu'
          , jpg: require('../assets/tab/ditu2.jpg')
          , isActive: false
        },
        {
          name: '服务指南', png: require('../assets/tab/fuwu.jpg'), val: 'fuwu'
          , jpg: require('../assets/tab/fuwu2.jpg')
          , isActive: false
        },
      ],
      comName: 'richeng'
    }
  },
  methods: {
    toggleBackground (item) {
      // 切换当前项的isActive状态
      item.isActive = !item.isActive;
      // 如果需要的话，可以记录当前激活的项的值
      this.comName = item.val;
      // 如果需要只允许一个项被激活，则关闭其他所有项的isActive
      this.list.forEach(listItem => {
        if (listItem !== item) {
          listItem.isActive = false;
        }
      });
    }
  }
}
</script>
<style  scoped lang = "scss" >
.home {
  width: 750px;
  .home_bg {
    width: 750px;
    height: 320px;
    background-image: url("../assets/image/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no - repeat;
  }
  .home_main {
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .list {
    width: 25%; /* 每行 4 个，每个占 25%宽度 */
    padding: 10px;
    box-sizing: border-box;
  }

  .item {
    width: 150px;
    height: 170px; /* 您可以根据实际图片的需要调整高度 */
    background-size: cover;
    background-position: center;
  }

  .name {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-left: 14px;
    padding-top: 85%;
    color: #058ee8;
  }
  .home_line {
    height: 2px;
    border: 2px solid #058ee8;
    margin: 30px;
  }
}
</style >