<template>
  <div class="login">
    <div class="foot">
      <div class="foot_box">
        <div>
          <codeCom></codeCom>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import codeCom from '../components/home/code.vue'
export default {
  components: {
    codeCom
  }
}
</script>
<style  scoped lang = "scss" >
.login {
  width: 750px;
  height: 1080px;
  background-image: url("../assets/image/logo.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.foot {
  position: absolute;
  background: #fff;
  top: 73%;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// .foot_box {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
</style>