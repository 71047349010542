<template>
  <div class="code">
    <div class="row-center captcha_input_wrapper">
      <input
        v-for="(item, index) in captchas"
        :key="index"
        v-model="item.num"
        :id="'captcha' + index"
        @input="inputFinash(index)"
        @focus="adjust(index)"
        @keydown="inputDirection(index)"
        class="captcha_input_box row-center"
        :class="[index <= activeInput ? 'active' : '']"
        type="tel"
        maxlength="1"
      />
    </div>
    <div class="btn">
      <div
        style="
          width: 100%;
          height: 40px;
          border-radius: 20px;
          line-height: 40px;
          color: #fff;
          background: #999999;
          font-size: 20px;
          text-align: center;
        "
        :style="{ backgroundColor: !active ? '#999999' : '#088fe8' }"
      >
        进入行程
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeInput: 0,
      captchas: [{ num: '' }, { num: '' }, { num: '' }, { num: '' }],
      active: false
    }
  },
  //   页面加载后聚焦第一个
  mounted () {
    this.active = false
    let dom = document.getElementById('captcha' + this.activeInput)
    dom.focus()
  },

  methods: {
    // 自动校准输入顺序
    adjust (index) {
      let dom = document.getElementById('captcha' + this.activeInput)
      if (index !== this.activeInput && dom) {
        dom.focus()
      }
    },

    // 控制前后方向
    inputDirection (index) {
      let val = this.captchas[index].num

      // 回退键处理
      if (event.keyCode === 8 && val === '') {
        // 重新校准
        let dom = document.getElementById('captcha' + (index - 1))
        this.activeInput = index - 1
        if (dom) dom.focus()
      }

      // 检查非回退键输入
      if (event.keyCode !== 8 && val !== '') {
        let dom = document.getElementById('captcha' + (index + 1))
        this.activeInput = index + 1
        if (dom) dom.focus()
      }
    },

    // 输入框相互联动
    inputFinash (index) {
      const val = this.captchas[index].num.trim()

      // 验证输入是否为数字
      if (!/^\d?$/.test(val)) {
        // 如果不是数字或者超过一个字符，则清除输入
        this.captchas[index].num = ''
        return
      }
      this.active = false
      // 检查是否所有输入框都已填满
      if (index === this.captchas.length - 1) {
        const code = this.captchas.map(x => x.num).join('')
        if (code.length === 4) {
          this.active = true
          console.log('code ', code);
          if (code !== '0594') {
            this.$toast.fail("行程码无效");
          } else {
            this.$router.push('/home')
          }
        }
      }
      // 根据输入内容移动到下一个或上一个输入框
      this.activeInput = val ? index + 1 : index - 1
      // 调整焦点
      let dom = document.getElementById('captcha' + this.activeInput)
      if (dom) dom.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.code {
  display: flex;
  flex-direction: column;
}
.btn {
  margin-top: 30px;
  ::v-deep .van-button--mini {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    color: #fff;
    background: #999999;
    span {
      font-size: 20px !important;
    }
  }
}

.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.captcha_input_wrapper {
  width: 100%;
}

.captcha_input_box {
  width: 90px;
  height: 90px;
  margin-right: 12px;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  border: 1px solid #249ceb;
  font-size: 60px;
  text-align: center;
  color: #000;
  outline: none;
  background: #f0fdff;
}
</style>
