<template>
  <div class="fuwu">
    <div class="home_divider">
      <van-divider
        dashed
        contentPosition="center"
        textColor="#088fe8"
        fontSize="24"
        borderColor="#088fe8"
        >温馨提示</van-divider
      >
    </div>
    <div class="fuwu_box">
      <div class="title">{{ stringObj[0].value }}</div>
      <div>{{ stringObj[1].gidName }}</div>
      <div>
        <span>{{ local.date }}，</span><span>{{ local.wea }}</span
        ><span
          ><span>{{ local.tem2 }}°C-</span
          ><span>{{ local.tem1 }}°C,</span></span
        ><span>{{ local.win }}，</span>
      </div>
      <div>{{ stringObj[2].gidName }}</div>
      <div>
        <span>{{ stringObj[2].value }}</span>
      </div>
      <div>{{ stringObj[3].value }}</div>
      <div>{{ stringObj[4].value }}</div>
      <div>{{ stringObj[5].gidName }}</div>
      <div class="other">{{ stringObj[5].value }}</div>
      <div class="other">{{ stringObj[6].value }}</div>
      <div class="other">
        {{ stringObj[7].value }}
      </div>
      <div class="other">{{ stringObj[8].value }}</div>
    </div>
    <div class="fuwu_weather">
      <div class="weather">
        <div class="title">天气情况</div>
        <div class="line"></div>
      </div>
      <div class="date_weather">
        <div>{{ M }}日</div>
        <div>
          <span>{{ local.wea }}</span
          ><span
            ><span>{{ local.tem2 }}°C-</span
            ><span>{{ local.tem1 }}°C,</span></span
          ><span>{{ local.win }}，</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import { getScheduleinfoApi } from "@/api/home.js"
import axios from 'axios'
export default {
  name: "fuwu",
  data () {
    return {
      stringObj: [],
      // 当地信息（城市、时间、地点、天气）
      local: {
      },
      M: ""
    }
  },
  mounted () {
    this.getList()
    // this.getCityPosition()
    // this.getCityWeather()
    this.getLocalCity()
    let date = new Date()
    this.M = date.getDate()
  },
  methods: {
    getLocalCity () {
      var data = {
        key: 'RDUBZ-WSMKI-GTUG7-52JZT-5FFE3-QZBBX'
      }
      var url = 'https://apis.map.qq.com/ws/location/v1/ip' //这个就是地理位置信息的接口
      data.output = 'jsonp'
      this.$jsonp(url, data)
        .then((res) => {
          console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaa', res)
          this.location = res.result.location
          axios.get(`http://v0.yiketianqi.com/free/v2030?city=&cityid=&adcode=&appid=${61133539}&appsecret=84UEsyQG&lng=${res.result.location.lng}&lat=${res.result.location.lat}&aqi=&hours=`).then(res => {
            this.local = res.data
            console.log('date', this.local);
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getList () {
      this.leftList = []
      this.rightList = []
      let params = {
        id: 6
      }
      getScheduleinfoApi(params).then(res => {
        this.stringObj = res
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.fuwu {
  .home_divider {
    margin: 0px 50px;
    ::v-deep .van-divider {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 16px 0;
      color: #018ce7;
      font-size: 18px;
      line-height: 24px;
      border-color: #018ce7;
      border-style: dashed;
      border-width: 10 !important;
    }
    ::v-deep .van-divider::after,
    .van-divider::before {
      border-width: 3px 0 0;
    }
  }
  .fuwu_box {
    margin: 0px 50px;
    border-radius: 24px;
    border: 1px solid #018ce7;
    padding: 20px;
    font-size: 30px;
    color: #000;
    div {
      margin-bottom: 5px;
      span {
        font-size: 30px;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .other {
      margin-left: 10px;
    }
  }
  .fuwu_weather {
    margin: 20px 50px;
    color: #018ce7;
    font-size: 30px;
    .weather {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .title {
        width: 160px;
      }
      .line {
        width: calc(100% - 140px);
        border: 1px solid #018ce7;
        margin-left: 10px;
      }
    }
    .date_weather {
      margin-top: 20px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #018ce7;
      padding: 30px;
      border-radius: 24px;
      :first-child {
        width: 80px;
        margin-right: 40px;
      }
      :last-child {
        width: calc(100% - 100px);

        span {
          font-size: 30px;
          color: #000;
        }
      }
    }
  }
}
</style>