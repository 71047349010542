<template>
  <div>
    <div class="container">
      <div
        class="div_item"
        v-for="(item, index) of 2"
        :key="index"
        :class="{ active: currentIndex === index }"
      ></div>
    </div>
    <div class="kaocha">
      <van-swipe
        class="my-swipe"
        indicator-color="white"
        :autoplay="false"
        :show-indicators="true"
        :indicator-active-class="'indicator-active'"
        :indicator-inactive-class="'indicator-inactive'"
        @change="handleChange"
      >
        <van-swipe-item class="item_image">
          <div>
            <video
              ref="video1"
              id="video2"
              class="video-js vjs-default-skin"
              controls
              :autoplay="true"
              preload="auto"
              x5-playsinline=""
              webkit-playsinline=""
              playsinline=""
              poster=""
              x-webkit-airplay="allow"
              src="../../assets/image/five/video1.mp4"
            ></video>
          </div>
          <div class="item_value">
            {{ stringObj[0].value }}
          </div>
        </van-swipe-item>
        <van-swipe-item class="item_image">
          <div>
            <video
              ref="video2"
              id="video2"
              class="video-js vjs-default-skin"
              controls
              :autoplay="true"
              preload="auto"
              x5-playsinline=""
              webkit-playsinline=""
              playsinline=""
              poster=""
              x-webkit-airplay="allow"
              src="../../assets/image/five/video2.mp4"
            ></video>
          </div>
          <div class="item_value">
            {{ stringObj[1].value }}
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
import { getScheduleinfoApi } from "@/api/home.js";
import video1 from '../../assets/image/five/video1.mp4';
import video2 from '../../assets/image/five/video2.mp4';

export default {
  name: 'Carousel',
  components: {
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem,
  },
  data () {
    return {
      stringObj: [],
      currentIndex: 0,
      videoList: [
        {
          path: video1
        },
        {
          path: video2
        },
      ]
    };
  },
  mounted () {
    this.getList();
    this.initVideos();
  },
  methods: {
    getList () {
      let params = {
        id: 4
      };
      getScheduleinfoApi(params).then(res => {
        this.stringObj = res
        // this.stringObj = res.map((item, index) => {
        //   // 根据索引分配图片
        //   let path = null;
        //   switch (index) {
        //     case 0:
        //       path = video1;
        //       break;
        //     case 1:
        //       path = video2;
        //       break;
        //     default:
        //       path = null; // 如果有更多的元素，可以选择默认图片或不分配图片
        //   }
        //   // 返回包含 name 和 image 的新对象
        //   return { ...item, path };
        // });
        console.log(' this.videoList =', this.stringObj);
      })
    },
    handleChange (index) {
      this.currentIndex = index;
    },
    initVideos () {
      // 获取所有视频元素
      const videos = this.$refs;

      // 遍历所有视频元素并设置样式
      Object.keys(videos).forEach((key) => {
        if (key.startsWith('video')) {
          const videoElement = videos[key][0];
          videoElement.style.width = '100%';
          videoElement.style.height = '100%';
          videoElement.style.objectFit = 'cover';
          videoElement.style.position = 'absolute';
          videoElement.style.top = '50%';
          videoElement.style.left = '50%';
          videoElement.style.transform = 'translate(-50%, -50%)';
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* 间距 */
}
.div_item {
  width: 26px;
  height: 26px;
  border-radius: 50%; /* 圆形 */
  background-color: rgba(0, 0, 0, 0.3);
  &.active {
    background-color: #088fe8; /* 当前选中的圆点颜色 */
  }
}
.kaocha {
  margin: 0 20px;
  padding: 0 20px;
  overflow: auto;
}

/* 覆盖 Vant 的默认样式 */
::v-deep .van-swipe__indicators {
  bottom: 20px; /* 根据需要调整指示器距离底部的距离 */
}

/* 自定义选中状态的指示器颜色 */
::v-deep .van-swipe__indicator--active {
  background-color: white !important; /* 绿色指示器 */
}

/* 可以添加更多的样式来调整指示器的大小和形状 */
::v-deep .van-swipe__indicator {
  width: 20px; /* 指示器的宽度 */
  height: 3px; /* 指示器的高度 */
  border-radius: 0; /* 去除圆角 */
  background-color: white; /* 非选中状态的颜色 */
  opacity: 0.5; /* 非选中状态的透明度 */
  margin: 0 5px; /* 指示器之间的间距 */
}

.item_image {
  // position: relative; /* 确保内部元素相对于这个容器定位 */
  width: 100%;
  height: 1200px; /* 保持不变，但确保没有其他固定高度影响滑动 */
  // overflow: auto; /* 确保内容不会溢出 */
  video {
    max-width: 100%;
    max-height: 300px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .item_value {
    // height: calc();
    display: flex;
    justify-content: center;
    align-items: center;
    color: #414244;
    font-size: 32px;
    margin-top: 350px;
  }
}
</style>