<template>
  <div class="div">
    <div class="container">
      <div
        class="div_item"
        v-for="(item, index) of 4"
        :key="index"
        :class="{ active: currentIndex === index }"
      ></div>
    </div>
    <div class="kaocha">
      <van-swipe
        class="my-swipe"
        indicator-color="white"
        :autoplay="false"
        :show-indicators="true"
        :indicator-active-class="'indicator-active'"
        :indicator-inactive-class="'indicator-inactive'"
        @change="handleChange"
      >
        <van-swipe-item v-for="(item, inx) in stringObj" :key="inx">
          <div class="item">
            <div class="item_gidName">{{ item.gidName }}</div>
            <div class="item_image">
              <img :src="item.image" alt="" />
            </div>
            <div class="item_value">{{ item.value }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
import { getScheduleinfoApi } from "@/api/home.js"
import a from '../../assets/image/five/a.png';
import b from '../../assets/image/five/b.png';
import c from '../../assets/image/five/c.png';
import d from '../../assets/image/five/d.png';
export default {
  name: 'Carousel',
  components: {
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem,
  },
  mounted () {
    this.getList()
  },
  data () {
    return {
      stringObj: [],
      currentIndex: 0
    };
  },
  methods: {
    handleChange (index) {
      this.currentIndex = index;
    },
    getList () {
      this.leftList = []
      this.rightList = []
      let params = {
        id: 5
      }
      getScheduleinfoApi(params).then(res => {
        this.stringObj = res.map((item, index) => {
          // 根据索引分配图片
          let image = null;
          switch (index) {
            case 0:
              image = b;
              break;
            case 1:
              image = a;
              break;
            case 2:
              image = c;
              break;
            case 3:
              image = d;
              break;
            default:
              image = null; // 如果有更多的元素，可以选择默认图片或不分配图片
          }
          // 返回包含 name 和 image 的新对象
          return { ...item, image };
        });
        console.log(' this.stringObj =', this.stringObj);
      })
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* 间距 */
}
.div_item {
  width: 26px;
  height: 26px;
  border-radius: 50%; /* 圆形 */
  background-color: rgba(0, 0, 0, 0.3);
  &.active {
    background-color: #088fe8; /* 当前选中的圆点颜色 */
  }
}
.kaocha {
  margin: 20px;
  padding: 0 20px;
}

/* 覆盖 Vant 的默认样式 */
::v-deep .van-swipe__indicators {
  bottom: 20px; /* 根据需要调整指示器距离底部的距离 */
}

/* 自定义选中状态的指示器颜色 */
::v-deep .van-swipe__indicator--active {
  background-color: white !important; /* 绿色指示器 */
}

/* 可以添加更多的样式来调整指示器的大小和形状 */
::v-deep .van-swipe__indicator {
  width: 20px; /* 指示器的宽度 */
  height: 3px; /* 指示器的高度 */
  border-radius: 0; /* 去除圆角 */
  background-color: white; /* 非选中状态的颜色 */
  opacity: 0.5; /* 非选中状态的透明度 */
  margin: 0 5px; /* 指示器之间的间距 */
}
.item_gidName {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 32px;
  font-weight: 700;
}
.item_image {
  margin: 20px 0;
  width: 100%;
  height: 300px;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.item_value {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #414244;
  font-size: 32px;
}
</style>