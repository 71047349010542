<template>
  <div>
    <div class="home_date">
      <div>2024年07月10日</div>
    </div>
    <div class="home_btn">
      <div class="home_btn">
        <div
          class="one"
          @click="one(1)"
          :class="activeIndex == 1 ? 'active' : 'one'"
        >
          第一组
        </div>
        <div
          class="one"
          @click="one(2)"
          :class="activeIndex == 2 ? 'active' : 'one'"
        >
          第二组
        </div>
      </div>
    </div>
    <div class="home_divider">
      <van-divider
        dashed
        contentPosition="center"
        textColor="#088fe8"
        fontSize="24"
        borderColor="#088fe8"
        >日程安排</van-divider
      >
    </div>
    <div class="home_title">
      <img src="../../assets//image/time.png" alt="" />
      <div>2024年07月10日</div>
    </div>
    <div class="home_steps">
      <van-steps direction="vertical">
        <van-step v-for="(item, idx) in steps" :key="idx">
          <div>
            <span>{{ item.value }}</span>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
import { getScheduleinfoApi } from "@/api/home.js"
export default {
  name: 'richeng',
  data () {
    return {
      activeIndex: 1,
      steps: [
        {
          time: '08:30',
          val: '前往闽中革命纪念园'
        },
        {
          time: '08:30',
          val: '45参观闽中支队司令部展馆'
        },
        {
          time: '08:30',
          val: '参观老鹰尖生态馆'
        },
        {
          time: '08:30',
          val: '30闽中招待所就餐'
        },
        {
          time: '08:30',
          val: '午休'
        },
        {
          time: '08:30',
          val: '参观知青农场（发展壮大村级集体经济、新农人助力多村振兴)'
        },
        {
          time: '08:30',
          val: '30参观科技小院'
        },
        {
          time: '08:30',
          val: '40返回'
        },
      ]
    }
  },
  mounted () {
    this.one()
  },

  methods: {
    one (val) {
      this.activeIndex = val ? val : 1
      this.getList()
    },
    getList () {
      this.steps = []
      let params = {
        id: 1
      }
      getScheduleinfoApi(params).then(res => {
        const foundItem = res.filter(item => this.activeIndex === item.gid);
        if (foundItem) {
          this.steps = foundItem;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home_date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  div {
    width: 270px;
    height: 120px;
    margin-right: 10px;
    margin-bottom: 10px;
    line-height: 120px;
    font-size: 28px;
    text-align: center;
    border-radius: 24px;
    background: linear-gradient(180deg, #018ce7, #87cdf5);
  }
}
.home_btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  margin-top: 15px;
}
.one {
  width: 120px;
  height: 82px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 82px;
  font-size: 28px;
  border-radius: 24px;
  background-color: #f0fdff;
  color: #666;
  border: 1px solid #088fe8;
  text-align: center;
}
.active {
  width: 120px;
  height: 82px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 82px;
  font-size: 28px;
  text-align: center;
  border-radius: 24px;
  color: #fff;
  background: linear-gradient(180deg, #018ce7, #87cdf5);
  text-align: center;
}
.home_divider {
  margin: 0px 80px;
  ::v-deep .van-divider {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 16px 0;
    color: #018ce7;
    font-size: 18px;
    line-height: 24px;
    border-color: #018ce7;
    border-style: dashed;
    border-width: 10 !important;
  }
  ::v-deep .van-divider::after,
  .van-divider::before {
    border-width: 3px 0 0;
  }
}
.home_title {
  margin: 0px 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
  div {
    font-size: 32px;
    margin-left: 20px;
  }
}
.home_steps {
  margin: 20px 60px 0 60px;

  ::v-deep .van-step__icon--active,
  .van-step__icon--finish,
  .van-step__title--active,
  .van-step__title--finish {
    color: #088fe8;
  }
  ::v-deep .van-step__icon--active,
  .van-step__icon--finish,
  .van-step__title--active,
  .van-step__title--finish {
    div {
      span {
        color: #333;
      }
    }
  }
  ::v-deep .van-step__circle {
    background-color: #088fe8;
  }

  ::v-deep .van-step__title {
    div {
      span {
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>