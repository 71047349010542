<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>

export default {
  name: "App",
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

